import { publish } from '@/utils/event-bus'

export interface RecipeDetails {
  title: string
  ingredients: string
  topTip: string
  methods: string[]
  cookTime?: string | null
  difficulty?: string | null
  serves?: string | null
}

export const SELECTORS = {
  TITLE: '.detail-panel__page-title',
  FACTS: '.recipe-fact__item h6',
  INGREDIENTS: '.ingredients-rich-text',
  METHOD_BLOCKS: '.recipe-page--method .rich-text',
  TOP_TIP: '.recipe-page__top-tip .rich-text',
  PRINT_BLOCK: 'print-block',
} as const

export const recipeIcons = {
  cookTime: (time: string) => `
    <div class="recipe-page-print__icon">
      <svg width="32" height="32" viewBox="0 0 32 32" data-icon="cooking-time"><g fill="#49C5B1"><path d="M14.44 17.572c-.688-.721-.549-1.686.277-2.26l4.6-3.214c.436-.304.888.157.584.582l-3.21 4.596c-.582.826-1.547.974-2.252.296"></path><path fill-rule="evenodd" d="M16 24a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0 2c5.523 0 10-4.477 10-10S21.523 6 16 6 6 10.477 6 16s4.477 10 10 10" clip-rule="evenodd"></path></g></svg>
      <p class="type-subtitle-sm">${time}</p>
    </div>
  `,

  difficulty: (level: string) => `
    <div class="recipe-page-print__icon">
      <svg width="32" height="32" viewBox="0 0 32 32" data-icon="not-too-tricky"><g fill="#49C5B1"><path d="M6.397 24h3.206c.838 0 1.397-.45 1.397-1.113v-7.774C11 14.45 10.441 14 9.603 14H6.397C5.559 14 5 14.45 5 15.113v7.774C5 23.55 5.559 24 6.397 24"></path><path stroke="#49C5B1" stroke-width="2" d="M17.603 23h-3.206c-.244 0-.353-.067-.38-.089l-.011-.01-.002-.002v-.002l-.001-.005-.003-.03V11.138l.004-.035V11.1l.002-.001.01-.011c.028-.022.137-.089.38-.089h3.207c.234 0 .346.065.378.091l.013.013.002.002v.002l.002.003.002.027v11.724l-.002.027-.001.003v.001h-.001l-.002.003-.013.013c-.032.026-.144.091-.378.091Z"></path><path fill-opacity=".3" d="M22.394 24h3.225c.823 0 1.381-.43 1.381-1.067V8.067C27 7.431 26.442 7 25.62 7h-3.226C21.57 7 21 7.43 21 8.067v14.866c0 .636.57 1.067 1.394 1.067"></path></g></svg>
      <p class="type-subtitle-sm">${level}</p>
    </div>
  `,

  serves: (amount: string) => `
    <div class="recipe-page-print__icon">
      <svg width="32" height="32" viewBox="0 0 32 32" class="print-icon" data-icon="serves"><path fill="none" stroke="#49C5B1" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2" d="M18 18h-5c-2.762 0-5 2.015-5 4.5v.75s2.604.75 7.5.75 7.5-.75 7.5-.75v-.75c0-2.485-2.238-4.5-5-4.5Zm-6-6.818C12 9.425 13.567 8 15.5 8S19 9.425 19 11.182 17.433 15 15.5 15 12 12.939 12 11.182Z"></path></svg>
      <p class="type-subtitle-sm">${amount}</p>
    </div>
  `,
}

export const buildHTML = ({
  title,
  ingredients,
  topTip,
  methods = [],
  cookTime,
  difficulty,
  serves,
}: RecipeDetails) => {
  const icons = [
    cookTime && recipeIcons.cookTime(cookTime),
    difficulty && recipeIcons.difficulty(difficulty),
    serves && recipeIcons.serves(serves),
  ]
    .filter(Boolean)
    .join('')

  const tipHtml = topTip
    ? `<div>
        <p class="type-subtitle">Top tip</p>
        ${topTip}
      </div>`
    : ''

  const iconsHtml = icons
    ? `<div class="recipe-page-print__icons">
        ${icons}
      </div>`
    : ''

  const html = `
    <div class="recipe-page-print">
      <div class="recipe-page-print__title">
        <p class="type-h1">${title}</p>
      </div>

        ${iconsHtml}

      <div class="recipe-page-print__content">
        <div class="recipe-page-print__content-item">
          <p class="type-h2 recipe-page-print__content-title">Ingredients</p>
          <div class="ingredients-rich-text">
            ${ingredients}
          </div>

          ${tipHtml}
        </div>
        
        <div class="recipe-page-print__content-item method">
          <p class="type-h2 recipe-page-print__content-title">Method</p>
          ${methods.map((method) => `<div class="rich-text">${method}</div>`).join('')}
        </div>
      </div>
    </div>
  `

  const printPageBlock = document.createElement('div')
  printPageBlock.id = SELECTORS.PRINT_BLOCK
  printPageBlock.innerHTML = html
  return printPageBlock
}

export const getRecipeDetails = (): RecipeDetails => {
  const title = document?.querySelector(SELECTORS.TITLE)?.textContent || ''
  const facts = document?.querySelectorAll(SELECTORS.FACTS) || []
  const ingredients = document?.querySelector(SELECTORS.INGREDIENTS)?.innerHTML || ''
  const methodBlocks = document?.querySelectorAll(SELECTORS.METHOD_BLOCKS) || ''
  const topTip = document?.querySelector(SELECTORS.TOP_TIP)?.innerHTML || ''

  const [cookTime, difficulty, serves] = [...facts].map((fact) => fact.textContent)
  const methods = [...methodBlocks].map((block) => block.innerHTML)

  return { title, ingredients, topTip, methods, cookTime, difficulty, serves }
}

const onBeforePrint = () => {
  const recipeDetails = getRecipeDetails()
  const html = buildHTML(recipeDetails)
  document.body.appendChild(html)
}

const onPrintRecipe = () => {
  const page = document.querySelector('[data-recipe-id]') as HTMLElement | null
  const id = page?.dataset?.recipeId || ''
  const title = page?.dataset?.recipeTitle || ''
  publish('print_page', { type: 'recipe', item_id: id, title })
  window.print()
}

const onAfterPrint = () => {
  document.getElementById(SELECTORS.PRINT_BLOCK)?.remove()
}

document.getElementById('print-recipe-btn')?.addEventListener('click', onPrintRecipe)
window.addEventListener('beforeprint', onBeforePrint)
window.addEventListener('afterprint', onAfterPrint)
