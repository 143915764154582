import { getVerifiedUserId } from '@/utils/get-verified-user-id'
export const initReviewsWidget = async () => {
  const userId = await getVerifiedUserId()
  const isRecipeReview = document.querySelector('main')?.hasAttribute('data-recipe-id')
  const noReviewsCopy =
    document.querySelector('section[data-no-reviews-copy]')?.getAttribute('data-no-reviews-copy') ||
    null
  const Id =
    document.querySelector('main')?.getAttribute('data-recipe-id') ||
    document.getElementById('product-panel')?.getAttribute('data-product-id')

  // Show login message if no user ID
  if (!userId) {
    document.getElementById('loginMessage')?.classList.remove('hidden')
  }

  const script = document.createElement('script')
  script.src = 'https://widget.reviews.co.uk/polaris/build.js'
  script.onload = async () => {
    // @ts-expect-error - ignore next line for TypeScript checks
    // eslint-disable-next-line no-undef
    if (typeof ReviewsWidget === 'function') {
      // @ts-expect-error - ignore next line for TypeScript checks
      // eslint-disable-next-line no-undef
      new ReviewsWidget('#ReviewsWidget', {
        //Your REVIEWS.io Store ID and widget type:
        store: 'https-www.jamieoliver.com',
        widget: 'polaris',

        //Content settings (store_review,product_review,third_party_review,questions). Choose what to display in this widget:
        options: {
          types: 'product_review',
          enable_sentiment_analysis: true,
          lang: 'en',
          //Possible layout options: bordered, large and reverse.
          layout: '',
          //How many reviews & questions to show per page?
          per_page: 6,
          store_review: {
            hide_if_no_results: false,
          },
          third_party_review: {
            hide_if_no_results: false,
          },
          //Product specific settings. Provide product SKU for which reviews should be displayed:
          product_review: {
            //Display product reviews - include multiple product SKUs seperated by Semi-Colons (Main Indentifer in your product catalog )
            sku: Id,
            hide_if_no_results: false,
          },
          //Questions settings:
          questions: {
            hide_if_no_results: false,
            enable_ask_question: true,
            enable_ask_question_button_style: false, //Show "be the first to ask a question" text as a button
            show_dates: true,
            //Display group questions by providing a grouping variable, new questions will be assigned to this group.
            grouping:
              '[Group questions by providing a grouping variable here or a specific product SKU]',
          },
          //Header settings:
          header: {
            enable_summary: true, //Show overall rating & review count
            enable_ratings: true,
            enable_attributes: false,
            enable_image_gallery: true, //Show photo & video gallery
            enable_percent_recommended: false, //Show what percentage of reviewers recommend it
            enable_write_review: !!userId, //Show "Write Review" button
            enable_ask_question: false, //Show "Ask Question" button
            enable_sub_header: true, //Show subheader
            rating_decimal_places: 1,
            use_write_review_button: true, //Show "be the first to leave a review" text as a button
            enable_if_no_results: false, //Show header when there are no results
          },

          //AI summary settings
          sentiment: {
            badge_text: 'AI Review Summary',
          },

          //Filtering settings:
          filtering: {
            enable: true, //Show filtering options
            enable_text_search: true, //Show search field
            enable_sorting: true, //Show sorting options (most recent, most popular)
            enable_product_filter: false, //Show product options filter
            enable_media_filter: true, //Show reviews with images/video/media options
            enable_overall_rating_filter: true, //Show overall rating breakdown filter
            enable_language_filter: false, // Filter by review language
            enable_language_filter_language_change: false, // Update widget language based on language selected
            enable_ratings_filters: false, //Show product attributes filter
            enable_attributes_filters: false, //Show author attributes filter
            enable_expanded_filters: false, //Show filters as a section instead of dropdown
          },

          //Review settings:
          reviews: {
            enable_avatar: true, //Show author avatar
            enable_reviewer_name: true, //Show author name
            enable_reviewer_address: true, //Show author location
            reviewer_address_format: 'city, country', //Author location display format
            enable_verified_badge: true, //Show "Verified Customer" badge
            enable_subscriber_badge: true, //Show "Verified Subscriber" badge
            review_content_filter: 'undefined', //Filter content
            enable_reviewer_recommends: false, //Show "I recommend it" badge
            enable_attributes: false, //Show author attributes
            enable_product_name: false, //Show display product name
            enable_review_title: false, //Show review title
            enable_replies: true, //Show review replies
            enable_images: true, //Show display review photos
            enable_ratings: false, //Show product attributes (additional ratings)
            enable_share: false, //Show share buttons
            enable_helpful_vote: true, //Show "was this helpful?" section
            enable_helpful_display: true, //Show how many times times review upvoted
            enable_report: true, //Show report button
            enable_date: true, //Show when review was published
            enable_third_party_source: true, //Show third party source
            enable_duplicate_reviews: undefined, //Show multiple reviews from the same user
          },
        },
        //Translation settings
        translations: {
          'Verified Customer': 'Verified Customer',
          'no reviews collected for this product yet': noReviewsCopy || 'no reviews collected yet',
          ...(isRecipeReview
            ? {
                'What would you rate this product?': 'What would you rate this recipe?',
                'product reviews': 'recipe reviews',
                'tell us your feedback about the product': 'Tell us your feedback about the recipe',
              }
            : {}),
        },
        //Style settings:
        styles: {
          //Base font size is a reference size for all text elements. When base value gets changed, all TextHeading and TexBody elements get proportionally adjusted.
          '--base-font-size': '16px',

          //Button styles (shared between buttons):
          '--common-button-font-family': 'p22 Underground',
          '--common-button-font-size': '14px',
          '--common-button-font-weight': '400',
          '--common-button-letter-spacing': '1.12px',
          '--common-button-text-transform': 'uppercase',
          '--common-button-vertical-padding': '15px',
          '--common-button-horizontal-padding': '32px',
          '--common-button-border-width': '2px',
          '--common-button-border-radius': '24px',

          //Primary button styles:
          '--primary-button-bg-color': '#337179',
          '--primary-button-border-color': '#337179',
          '--primary-button-text-color': '#ffffff',

          //Secondary button styles:
          '--secondary-button-bg-color': 'transparent',
          '--secondary-button-border-color': '#337179',
          '--secondary-button-text-color': '#337179',

          //Star styles:
          '--common-star-color': '#FBE122',
          '--common-star-disabled-color': '#FDF4B0',
          '--medium-star-size': '32px',
          '--small-star-size': '24px',

          //Heading styles:
          '--heading-text-color': '#254A5D',
          '--heading-text-font-weight': '600',
          '--heading-text-font-family': '',
          '--heading-text-line-height': '1.6',
          '--heading-text-letter-spacing': '0',
          '--heading-text-transform': 'none',

          //Body text styles:
          '--body-text-color': '#254A5D',
          '--body-text-font-weight': '400',
          '--body-text-font-family': 'inherit',
          '--body-text-line-height': '1.4',
          '--body-text-letter-spacing': '0',
          '--body-text-transform': 'none',

          //Input field styles:
          '--inputfield-text-font-family': 'inherit',
          '--input-text-font-size': '14px',
          '--inputfield-text-font-weight': '400',
          '--inputfield-text-color': '#254A5D',
          '--inputfield-border-color': 'rgba(0,0,0,0.2)',
          '--inputfield-background-color': '#F4F6F7',
          '--inputfield-border-width': '0',
          '--inputfield-border-radius': '24px',

          '--common-border-color': '#E9EDEF',
          '--common-border-width': '1px',
          '--common-sidebar-width': '304px',

          //Filters panel styles:
          '--filters-panel-bg-color': 'transparent',
          '--filters-panel-font-size': '16px',
          '--filters-panel-text-color': '16px',
          '--filters-panel-horizontal-padding': '0',
          '--filters-panel-vertical-padding': '0',

          //Slider indicator (for attributes) styles:
          '--slider-indicator-bg-color': 'rgba(0,0,0,0.1)',
          '--slider-indicator-button-color': '#FBE122',
          '--slider-indicator-width': '190px',

          //Badge styles:
          '--badge-icon-color': '#49C5B1',
          '--badge-icon-font-size': '16px',
          '--badge-text-color': '#49C5B1',
          '--badge-text-font-size': '13px',
          '--badge-text-letter-spacing': '0',
          '--badge-text-transform': 'inherit',

          //Author styles:
          '--author-font-size': '14px',
          '--author-text-transform': 'none',

          //Author avatar styles:
          '--avatar-thumbnail-size': '64px',
          '--avatar-thumbnail-border-radius': '100px',
          '--avatar-thumbnail-text-color': '#FFFFFF',
          '--avatar-thumbnail-bg-color': '#FFB549',

          //Product photo or review photo styles:
          '--photo-video-thumbnail-size': '120px',
          '--photo-video-thumbnail-border-radius': '16px',

          //Media (photo & video) slider styles:
          '--mediaslider-scroll-button-icon-color': '#337179',
          '--mediaslider-scroll-button-bg-color': 'rgba(233, 237, 239, 0.85)',
          '--mediaslider-overlay-text-color': '#FFFFFF',
          '--mediaslider-overlay-bg-color': 'rgba(51, 113, 121, 0.6))',
          '--mediaslider-item-size': '192px',

          //Pagination & tabs styles (normal):
          '--pagination-tab-text-color': '#254A5D',
          '--pagination-tab-text-transform': 'uppercase',
          '--pagination-tab-text-letter-spacing': '0',
          '--pagination-tab-text-font-size': '16px',
          '--pagination-tab-text-font-weight': '600',

          //Pagination & tabs styles (active):
          '--pagination-tab-active-text-color': '#254A5D',
          '--pagination-tab-active-text-font-weight': '600',
          '--pagination-tab-active-border-color': '#254A5D',
          '--pagination-tab-border-width': '1px',

          //AI summary styles
          '--sentiment-base-font-size': '15px',
          '--sentiment-panel-bg-color': '#EDF9F7',
          '--sentiment-panel-border-size': '0px',
          '--sentiment-panel-border-color': 'rgba(0, 0, 0, 0.1)',
          '--sentiment-panel-border-radius': '24px',
          '--sentiment-panel-shadow-size': '0px',
          '--sentiment-panel-shadow-color': 'rgba(0, 0, 0, 0.1)',
          '--sentiment-heading-text-color': '#0E1311',
          '--sentiment-heading-text-font-weight': '600',
          '--sentiment-heading-text-font-family': 'inherit',
          '--sentiment-heading-text-line-height': '1.4',
          '--sentiment-heading-text-letter-spacing': '0',
          '--sentiment-heading-text-transform': 'none',
          '--sentiment-body-text-color': '#254A5D',
          '--sentiment-body-text-font-weight': '400',
          '--sentiment-body-text-font-family': 'inherit',
          '--sentiment-body-text-line-height': '1.6',
          '--sentiment-body-text-letter-spacing': '0',
          '--sentiment-body-text-transform': 'none',
          '--sentiment-panel-vertical-padding': '40px',
          '--sentiment-panel-horizontal-padding': '40px',
          '--sentiment-header-text-color': '#337179',
          '--sentiment-header-text-font-size': '13px',
          '--sentiment-header-text-font-weight': '600',
          '--sentiment-header-bg-color': '#FFFFFF',
          '--sentiment-header-border-radius': '24px',
          '--sentiment-header-shadow-size': '1px',
          '--sentiment-header-shadow-color': '#0000000F',
          '--sentiment-header-vertical-padding': '14px',
          '--sentiment-header-horizontal-padding': '24px',
          '--sentiment-pagination-tab-text-font-size': '14px',
          '--sentiment-pagination-tab-text-font-weight': '400',
          '--sentiment-pagination-tab-text-color': 'rgba(51, 113, 121, 0.6)',
          '--sentiment-pagination-tab-text-transform': 'none',
          '--sentiment-pagination-tab-text-letter-spacing': '8%',
          '--sentiment-pagination-tab-active-text-color': '#254A5D',
          '--sentiment-pagination-tab-active-text-font-weight': '400',
          '--sentiment-pagination-tab-active-border-color': '#254A5D',
          '--sentiment-pagination-tab-border-width': '1px',
        },
      })
    } else {
      console.error('ratingSnippet function is not available.')
    }
  }

  script.onerror = () => {
    console.error('Failed to load the rating snippet script.')
  }

  document.body.appendChild(script)
}
