export const initRatingSnippet = async () => {
  const script = document.createElement('script')
  script.src = 'https://widget.reviews.co.uk/rating-snippet/dist.js'
  script.onload = () => {
    // @ts-expect-error - ignore next line for TypeScript checks
    // eslint-disable-next-line no-undef
    if (typeof ratingSnippet === 'function') {
      // @ts-expect-error - ignore next line for TypeScript checks
      // eslint-disable-next-line no-undef
      ratingSnippet('ruk_rating_snippet', {
        store: 'https-www.jamieoliver.com',
        mode: 'default',
        color: '#FBE122',
        linebreak: false,
        text: 'Reviews',
        singularText: 'Review',
        lang: 'en',
        usePolaris: false,
        showEmptyStars: false,
      })
    } else {
      console.error('ratingSnippet function is not available.')
    }
  }
  script.onerror = () => console.error('Failed to load the rating snippet script.')
  document.body.appendChild(script)
}
